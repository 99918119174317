import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'Shared/Common/debounce';
import {
  EventDispatcher,
  ON_MOBILE_QUICKSEARCH_TOGGLE,
} from 'Shared/Common/EventDispatcher';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { QuickSearch } from 'Cms/Pages/SearchPage/Search';
import { styled } from 'Theme/stitches.config';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import SearchInput from 'DesignComponents/Atoms/Input/SearchInput';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import QuickSearchCard from 'Commerce/Molecules/ProductCard/QuickSearchCard';
import SearchLinkResultModel from 'Models/Search/SearchLinkResultModel.interface';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import { useUserStateData } from '../../../../Shared/Providers/UserContextProvider';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';
import { Back } from 'DesignSystem/Icons';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';

import Button from 'Shared/Components/Buttons/Button';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import NoResults from '../NoResults';
import Overlay from 'DesignComponents/Atoms/OLD/Overlay/Overlay';
import FullSearchResultModel from 'Models/Search/FullSearchResultModel.interface';
import { useFilterData } from 'Shared/Providers/FilterProvider';
import { getSearchQueryParameter } from 'Shared/Common/Helpers';
import {
  NO_OF_MAGAZINES,
  NO_OF_PAGES,
  NO_OF_PRODUCTS,
} from 'Shared/Constants/common';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import { ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

type PropTypes = {
  onMenuClose: () => void;
};

function MobileQuickSearch({ onMenuClose }: PropTypes) {
  const {
    staticPages: { searchPage },
    languageRoute,
    searchSettings,
  } = useAppSettingsData();

  const { pageType } = useCurrentPage();
  const kexNavigate = useKexNavigate();
  const {
    account: { query: searchedQuery },
  } = useUserStateData();
  const [, dispatchFilterState] = useFilterData();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [result, setResult] = useState<FullSearchResultModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [resultHits, setResultHits] = useState<number>();
  const [magazinesState, setMagazinesState] = useState<
    SearchLinkResultModel[] | undefined
  >();
  const [productsState, setProductsState] = useState<
    SearchLinkResultModel[] | undefined
  >();
  const [pagesState, setPagesState] = useState<
    SearchLinkResultModel[] | undefined
  >();

  const onToggle = () => {
    if (!isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
        onMenuClose();
      }, 500);
    }
    setIsOpen(!isOpen);
  };

  const currentUrl = canUseDOM() && window.location.href;

  const {
    searchLabels: {
      quickSearchCategories,
      quickSearchPages,
      quickSearchProducts,
      showAllResults: showAllResultsText,
      noHits,
      search,
    },
  } = useTranslationData();

  const doSearch = () => {
    setIsOpen(false);
    if (query.length > 1) {
      dispatchFilterState({ type: 'setQuery', value: query });
      kexNavigate(`${searchPage}?query=${query}`);
    }
  };

  const doQuickSearch = useCallback(async () => {
    const res = await QuickSearch(
      searchPage,
      query,
      languageRoute,
      setIsLoading
    );

    if (
      (getSearchQueryParameter() === query || query === searchedQuery) &&
      pageType === 'SearchPage'
    ) {
      setIsOpen(false);
      return;
    }

    res && setResult(res);
  }, [searchPage, query, languageRoute, setIsLoading]);

  useEffect(() => setQuery(searchedQuery), [searchedQuery]);

  useEffect(() => {
    onMenuClose();
  }, [currentUrl]);

  useEffect(() => {
    debounce(() => {
      if (query.length >= 3) {
        doQuickSearch();
      }
    }, 300);
  }, [query, searchPage, doQuickSearch]);

  useEffect(() => {
    EventDispatcher.subscribe(ON_MOBILE_QUICKSEARCH_TOGGLE, onToggle);

    return () => {
      EventDispatcher.unsubscribe(ON_MOBILE_QUICKSEARCH_TOGGLE, onToggle);
    };
  });

  const showAllResults = () => {
    setIsOpen(false);
    dispatchFilterState({ type: 'setQuery', value: query });
    kexNavigate(`${searchPage}?query=${query}`);
  };

  // Calculate QuickSearch result hits
  useEffect(() => {
    if (result) {
      let hits: number | undefined;

      if (result.magazines?.availableItems) {
        hits = result.magazines?.availableItems;
        setMagazinesState(result?.magazines?.items.slice(0, NO_OF_MAGAZINES));
      } else {
        setMagazinesState(undefined);
      }

      if (result.pages?.availableItems) {
        hits = result.pages.availableItems;
        setPagesState(result?.pages?.items.slice(0, NO_OF_PAGES));
      } else {
        setPagesState(undefined);
      }

      if (searchSettings.searchForArticlesOverSubscriptionOffers) {
        if (result.articleProducts?.availableItems) {
          hits = result?.articleProducts?.availableItems;
          setProductsState(
            result.articleProducts?.items.slice(0, NO_OF_PRODUCTS)
          );
        } else {
          setProductsState(undefined);
        }
      } else {
        if (result.subscriptionOffers?.availableItems) {
          hits = result?.subscriptionOffers?.availableItems;
          setProductsState(
            result.subscriptionOffers?.items.slice(0, NO_OF_PRODUCTS)
          );
        } else {
          setProductsState(undefined);
        }
      }

      if (!hits) hits = 0;

      setResultHits(hits);
    }
  }, [result]);

  const ResetSearch = () => {
    setMagazinesState(undefined);
    setPagesState(undefined);
    setProductsState(undefined);
    setResultHits(undefined);
  };

  return (
    <Wrapper transform={isOpen} aria-modal="true">
      <Top>
        <BackButton onClick={() => setIsOpen(false)}>
          <Back color="primary" size="s" title="@@Stäng sök" />
        </BackButton>
        {isOpen && (
          <SearchInput
            doSearch={doSearch}
            query={query}
            setQuery={setQuery}
            hasFocus={true}
            placeholder={search}
            onReset={ResetSearch}
          />
        )}
      </Top>
      <QuickSearchWrapper>
        <>
          <Grid>
            {magazinesState && magazinesState.length > 0 && (
              <Row>
                <Heading
                  tag="h2"
                  size="s"
                  css={HeaderStyle}
                  id="categoriesSearchHeader"
                >
                  {quickSearchCategories}
                </Heading>

                <ResultList
                  aria-labelledby="categoriesSearchHeader"
                  key={'magazines'}
                >
                  {magazinesState?.map((item: any, index: number) => (
                    <li key={`li-magazine-${index}`}>
                      <QuickSearchCard
                        item={item}
                        onClick={onToggle}
                        imageScalingType={ImageScalingTypes.MINICART_MAGAZINE}
                      />
                    </li>
                  ))}
                </ResultList>
              </Row>
            )}
            {productsState && productsState.length > 0 && (
              <Row>
                <Heading
                  tag="h2"
                  size="s"
                  css={HeaderStyle}
                  id="productsSearchHeader"
                >
                  {quickSearchProducts}
                </Heading>

                <ResultList
                  aria-labelledby="categoriesSearchHeader"
                  key={'articleProducts'}
                >
                  {productsState?.map(
                    (item: SearchLinkResultModel, index: number) => {
                      return (
                        <li key={`li-articleProducts-${index}`}>
                          <QuickSearchCard
                            item={item}
                            onClick={onToggle}
                            imageScalingType={
                              ImageScalingTypes.QUICKSEARCH_PRODUCT_CARD
                            }
                          />
                        </li>
                      );
                    }
                  )}
                </ResultList>
              </Row>
            )}
            {pagesState && pagesState.length > 0 && (
              <Row>
                <Heading
                  tag="h2"
                  size="s"
                  css={HeaderStyle}
                  id="pagesSearchHeader"
                >
                  {quickSearchPages}
                </Heading>

                <ResultList aria-labelledby="pagesSearchHeader" key={'Pages'}>
                  {pagesState?.map((item: any, index) => (
                    <li key={`li-pages-${index}`}>
                      <InteractableContentLink
                        href={item.url}
                        target="_self"
                        onClick={onToggle}
                      >
                        <PageLink>{item.name}</PageLink>
                      </InteractableContentLink>
                    </li>
                  ))}
                </ResultList>
              </Row>
            )}
          </Grid>
          {typeof resultHits === 'number' && resultHits > 0 && (
            <ButtonWrapper>
              <Button<typeof PrimaryButton>
                props={{
                  color: 'Regular',
                  size: 'm',
                  text: showAllResultsText,
                  hug: 'width',
                }}
                element="PrimaryButton"
                onClick={showAllResults}
                isLoading={isLoading}
              />
            </ButtonWrapper>
          )}

          {typeof resultHits === 'number' && resultHits === 0 && (
            <NoResults>{`${noHits} "${query}"`}</NoResults>
          )}
        </>

        {isLoading && (
          <LoadingWrapper>
            <AnimatedIcon animation={isLoading && 'spinAnimation'}>
              <LoadingCircle />
            </AnimatedIcon>
          </LoadingWrapper>
        )}
      </QuickSearchWrapper>
      {isOpen && <Overlay isVisible={false} />}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  position: 'absolute',
  l: 0,
  t: 0,
  b: 0,
  r: 0,
  backgroundColor: '$surface',
  h: '100vh',
  transition: 'transform $300',
  overflow: 'hidden',
  variants: {
    sticky: {
      true: {
        position: 'fixed',
        t: 0,
        border: 'none',
      },
    },
    transform: {
      true: {
        overflowY: 'auto',
        transform: 'translateY(-30px)',
      },
      false: {
        transform: 'translateY(-200%)',
      },
    },
  },
  zIndex: 21,
});

const QuickSearchWrapper = styled('div', {
  p: 4,
  pt: 8,
  mt: 4,
  w: '100%',

  overflowY: 'auto',
  backgroundColor: '$searchBackgroundPrimary',
});

const Top = styled('div', {
  mb: 4,
  p: 4,
});

const Grid = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mb: 12,
  g: 10,
});

const ResultList = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const HeaderStyle = {
  fontSize: '$fontSize200',
  mb: 4,
};

const PageLink = styled('span', {
  fontFamily: 'fontSemiBold',
});

const ButtonWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 8,
  alignItems: 'center',
});

const BackButton = styled('button', {
  mb: 8,
});

const LoadingWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

export default MobileQuickSearch;
