type MenuStateType = {
  menuIsOpen: boolean;
  mountMenu: boolean;
};

type MenuActionType = {
  id?: number | null;
  type: 'mountAndOpen' | 'closeMenu' | 'unMountMenu';
};

/* eslint consistent-return: off */
const menuReducer = (state: MenuStateType, action: MenuActionType) => {
  const actionId = action.id || action.id === 0 ? action.id : null;

  switch (action.type) {
    case 'mountAndOpen': {
      return {
        ...state,
        menuIsOpen: true,
        mountMenu: true,
        menuId: actionId,
      };
    }
    case 'closeMenu': {
      return {
        ...state,
        menuIsOpen: false,
        menuId: actionId,
      };
    }
    case 'unMountMenu': {
      return {
        ...state,
        menuIsOpen: false,
        mountMenu: false,
        menuId: actionId,
      };
    }
  }
};

export default menuReducer;
