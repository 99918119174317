import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Back({ size, color, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <BaseIconPathStyle
        d="M2.25 9.41321H15.7911C17.1724 9.41321 18.4971 9.96192 19.4738 10.9386C20.4505 11.9153 20.9992 13.24 20.9992 14.6213C20.9992 16.0026 20.4505 17.3273 19.4738 18.304C18.4971 19.2807 17.1724 19.8295 15.7911 19.8295"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M6.4165 5.24683L2.25 9.41332L6.4165 13.5798"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Back;
