import usePreventBackgroundScroll from 'Shared/Hooks/usePreventBackgroundScroll';
import { styled } from 'Theme/stitches.config';

type PropTypes = {
  clickEvnt?: () => void;
  isVisible: boolean;
  overlayColor?: 'primary' | 'secondary';
};

function Overlay({
  clickEvnt,
  isVisible,
  overlayColor = 'primary',
}: PropTypes) {
  usePreventBackgroundScroll();

  return (
    <OverlayDiv
      onClick={clickEvnt}
      isVisible={isVisible}
      overlayColor={overlayColor}
    />
  );
}

export default Overlay;

const OverlayDiv = styled('div', {
  w: '100vw',
  h: '100vh',
  position: 'fixed',
  l: 0,
  t: 0,
  opacity: '0',
  zIndex: '$ModalOverlay',
  transition: 'all 0.25s ease-in-out',
  visibility: 'hidden',
  variants: {
    isVisible: {
      true: {
        visibility: 'initial',
        opacity: '0.5',
      },
    },
    overlayColor: {
      primary: {
        backgroundColor: '$modalOverlayPrimary',
      },
      secondary: {
        backgroundColor: '$modalOverlaySecondary',
      },
    },
  },
});
