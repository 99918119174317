import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Menu({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle d="M2.25 18.0031H21.75" stroke={color} strokeRound />
      <BaseIconPathStyle d="M2.25 12.0031H21.75" stroke={color} strokeRound />
      <BaseIconPathStyle d="M2.25 6.00305H21.75" stroke={color} strokeRound />
    </BaseSvgStyle>
  );
}

export default Menu;
